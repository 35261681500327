import React, { useState } from 'react';
import { EventEmitter } from './EventEmitter';
import Draggable from 'react-draggable';

function DesktopIcon({ ico, text, eventType, onClick }) {
    const [waitingClick, setWaitingClick] = useState(null);
    const [lastClick, setLastClick] = useState(0);

    const processClick = (e) => {
        if (lastClick && e.timeStamp - lastClick < 250 && waitingClick) {
            // double click
            setLastClick(0);
            clearTimeout(waitingClick);
            setWaitingClick(null);
            if (onClick) {
                onClick();
            } else {
                EventEmitter.dispatch(eventType, true);
            }
        } else {
            // single click
            setLastClick(e.timeStamp);
            setWaitingClick(setTimeout(() => {
                setWaitingClick(null);
            }, 251));
        }
    };

    const handleTouchStart = (e) => {
        processClick(e);
    };

    const handleClick = (e) => {
        processClick(e);
    };

    return (
        <div>
            <Draggable
                axis="both"
                handle=".handle"
                defaultPosition={{ x: 0, y: 0 }}
                position={null}
                grid={[25, 25]}
                scale={1}
            >
                <div 
                    className="ico handle" 
                    onClick={handleClick} 
                    onTouchStart={handleTouchStart}  n
                >
                    {ico}
                    <div>{text}</div>
                </div>
            </Draggable>
        </div>
    );
}

export default DesktopIcon;
