import { Frame } from '@react95/core'
import { Mapi32801, Mail3, Globe } from '@react95/icons' 
import React from 'react'

function Contact() {

    const Mailto = ({ email, subject, body, children }) => {
        return (
            <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
        );
    };

    return (
        <div>
            <Frame
                bg="white"
                boxShadow="in"
                height="100%"
                padding={10}
                style={{
                    overflowY: "auto",
                    maxHeight: "60vh",
                }}
            >
                <h2>Contact</h2>
                <div className='contact-container'>
                    <div className='contact'> 
                        <Mailto email="godfatherwin95@gmail.com" subject="Hello">
                            <Mail3 style={{ width: '7vw', height: '7vh' }} />
                            <span>
                                godfatherwin95@gmail.com
                            </span>
                        </Mailto>

                    </div>
                    <div className='contact'>
                        <a target='_blank' rel="noreferrer" href='https://t.me/THESOLGODFATHER'>
                            <Mapi32801 style={{ width: '7vw', height: '7vh' }} />
                            Telegram
                        </a>
                    </div>
                    <div className='contact'>
                        <a target='_blank' rel="noreferrer" href='https://x.com/Godfather_onsol'>
                            <Globe style={{ width: '7vw', height: '7vh' }}/>
                            $GODFATHER Twitter
                        </a>
                    </div>
                </div>
            </Frame>
        </div>
    )
}

export default Contact