// Loading.js

import React, { useEffect } from 'react';
import './Loading.css'; // Import your CSS file for styling
import loadSound from '../Star.mp3';

const Loading = () => {
  useEffect(() => {
    const audio = new Audio(loadSound);
    audio.play();
    
    
  }, []);

  return (
    <div className="loading-container">
      <video id="video-bg" autoPlay unmuted>
        <source src="Star2.mp4" type="video/mp4">  
        </source>
      </video>
    </div>
  );
};

export default Loading;
