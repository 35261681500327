import './App.css';
import React, { useState, useEffect } from 'react';
import WebsiteTaskbar from './components/Taskbar';
import WebsiteDesktop from './components/Desktop';
import Loading from '../src/Startup/Loading';
import Welcome from '../src/Startup/Welcome';
import { GlobalStyle } from '@react95/core';

function App() {
  const [stage, setStage] = useState('loading');

  useEffect(() => {
    setTimeout(() => setStage('welcome'), 12000); // Show loading screen for 5 seconds
  }, []);

  useEffect(() => {
    if (stage === 'welcome') {
      setTimeout(() => setStage('desktop'), 4000); // Show welcome screen for 5 seconds
    }
  }, [stage]);

  return (
    <div className="App">
      <div className='App-body'>
        <GlobalStyle />
        {stage === 'loading' && <Loading />}
        {stage === 'welcome' && <Welcome />}
        {stage === 'desktop' && (
          <>
            <WebsiteDesktop />
            <WebsiteTaskbar />
          </>
        )}
      </div>
    </div>
  );
}

export default App;
