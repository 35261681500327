import React from 'react';

function WolfensteinEmbed() {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: `
                    <style>
                        .iframe-container {
                            position: relative;
                            width: 100%;
                            height: 420px;
                            
                        }
                        .iframe-container iframe {
                            width: 100%;
                            height: 110%;
                            border: 0;
                             /* Scale down the iframe content */
                            transform-origin: top left; /* Scale from the top left corner */
                        }
                        
                    </style>
                    <div class="iframe-container">
                        <iframe src="./Wolfenstein3D/index.html"></iframe>
                    </div>
                `,
            }}
        />
    );
}

export default WolfensteinEmbed;
