import React from 'react';
import { Modal } from '@react95/core';
import ContentFactory from './NotepadContent/ContentFactory'; 

function Notepad({ ico, closeNotepad, selectedItem, isMobile, left, top, focused, width, height }) {
    if (!selectedItem) return null; // Handle undefined selectedItem

    const isEtf = selectedItem.id === 'etf';
    const isDocs = selectedItem.id === 'docs';
    const isMinesweeper = selectedItem.id === 'minesweeper';
    const isWin95coin = selectedItem.id === 'win95';
    const isWolfenstein = selectedItem.id === 'wolf';

    // Custom styles for Etf
    const etfStyles = {
        width: isMobile ? '90%' : width || 800, // custom width for Etf
        height: isMobile ? '80%' : height || 550, // custom height for Etf
        border: focused ? '2px solid #008080' : 'none',
        padding: '2px 2px',
        left: '17vw', // custom left position for Etf
        top: '3vh', // custom top position for Etf
    };

    // Custom styles for Wolfenstein
    const wolfStyles = {
        width: isMobile ? '90%' : width || 700, // custom width for Wolfenstein
        height: isMobile ? '80%' : height || 500, // custom height for Wolfenstein
        border: focused ? '2px solid #008080' : 'none',
        padding: '2px 2px',
        left: '20vw', // custom left position for Wolfenstein
        top: '5vh', // custom top position for Wolfenstein
    };

    // Custom styles for Docs in mobile view
    const docsStyles = {
        width: isMobile ? '90%' : width || 450,
        height: isMobile ? '55%' : height || 800,
        border: focused ? '2px solid #008080' : 'none',
        left: isMobile ? '0%' : left || '5%',
        top: isMobile ? '0%' : top || '3%',
    };

    // Default styles for other components
    const defaultStyles = {
        width: isMobile ? 'auto' : width || 450,
        height: isMobile ? 'auto' : height || 'auto',
        border: focused ? '2px solid #008080' : 'none',
        left: isMobile ? '5%' : left,
        top: isMobile ? '3%' : top,
    };

    // Use the appropriate styles based on the component
    const styles = isEtf ? etfStyles : (isWolfenstein ? wolfStyles : (isDocs ? docsStyles : defaultStyles));

    // Menu only for non-Etf, non-Docs, and non-Minesweeper components
    const menu = !(isEtf || isWolfenstein) ? [
        { name: 'File', list: [] },
        { name: 'Edit', list: [] },
        { name: 'View', list: [] },
        { name: 'Tools', list: [] },
        { name: 'Help', list: [] }
    ] : null;

    // Buttons only for non-Etf, non-Docs, non-Minesweeper, non-Win95coin, and non-Wolfenstein components
    const buttons = !(isEtf || isDocs || isMinesweeper || isWin95coin || isWolfenstein) ? [{ value: "Close", onClick: closeNotepad }] : null;

    return (
        <Modal
            icon={ico}
            title={`${selectedItem.name}`}
            closeModal={closeNotepad} // keep the close button for all
            buttons={buttons} // conditional buttons
            style={{
                ...styles, // Apply the styles here
            }}
            menu={menu}
        >
            <ContentFactory id={selectedItem.id} isMobile={isMobile} />
        </Modal>
    );
}

export default Notepad;
