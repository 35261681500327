import React from 'react';
import WolfensteinEmbed from '../WolfensteinEmbed';

function Wolfenstein() {
    return (
        <div>
            <WolfensteinEmbed />
        </div>
    );
}

export default Wolfenstein; 