import React, { useEffect } from 'react';
import '../Startup/Welcome.css'; // Add your styles here
import welcomeSound from '../StartSound.mp3';

const Welcome = () => {
  useEffect(() => {
    const audio = new Audio(welcomeSound);
    audio.play();
    
    
  }, []);

  return (
    <div className="welcome">
      <video id="video-bg" autoPlay unmuted>
        <source src="Startup2.mp4" type="video/mp4">  
        </source>
      </video>
    </div>
  );
};

export default Welcome;
