import React, { useState } from 'react';
import { List, TaskBar } from "@react95/core";
import { Explore, Awfxcg321305, FolderExe, Progman15, FolderFile, Help, HelpBook, Issue, Mail2, MediaVideo, Mplayer10, FolderExe2, MicrosoftExchange, MsDos, MicrosoftNetwork, Settings, FolderSettings, FolderPrint, FileFind, LoaderBat, Computer3, } from "@react95/icons";
import { EventEmitter } from "./EventEmitter";

import WinampPlayer from "./WinampPlayer"; // Import the new WinampPlayer component 

export default function WebsiteTaskbar(props) {
  const [showWinamp, setShowWinamp] = useState(false); 

  const handleClick = (x) => {
    switch (x) {
      case 'terrabit':
        window.open('');
        break;
      case 'winamp':
        setShowWinamp(true);
        break;
      default:
        if (typeof x === 'function') {
          x(); // If x is a function, call it
        } else {
          EventEmitter.dispatch(x); // Dispatch the event with x as the identifier
        }
        break;
    }
  }
  

  return (
    <>
      <TaskBar list={
        <List>
          <List.Item icon={<Mplayer10 />}>
            Video Player
            <List width={'153'}>
              <List.Item icon={<MediaVideo />} onClick={() => handleClick('video')}>
                $GODFATHER The Movie
              </List.Item>
            </List>
          </List.Item>
          <List.Item icon={<Progman15 variant="32x32_4"/>} onClick={() => handleClick('dtxs')}>
                  $GODFATHER Charts
          </List.Item>
          <List.Item icon={<Issue />} onClick={() => handleClick('about')}>
                About
              </List.Item>
          <List.Item icon={<Explore />} onClick={() => handleClick('docs')}>
            Internet Explorer
          </List.Item>
          <List.Divider />
          <List.Item icon={<FolderExe2 />}>
            <List width={'153'}>
              <List.Item icon={<FolderExe variant="16x16_4" />}>
                Accessories
              </List.Item>
              <List.Item icon={<FolderExe variant="16x16_4" />}>
                StartUp
              </List.Item>
              <List.Item icon={<MicrosoftExchange variant="16x16_4" />}>
                Microsoft Exchange
              </List.Item>
              <List.Item icon={<MsDos variant="16x16_32" />}>
                MS-DOS Prompt
              </List.Item>
              <List.Item icon={<MicrosoftNetwork variant="16x16_4" />}>
                The Microsoft Network
              </List.Item>
              <List.Item icon={<Explore />} onClick={() => handleClick('docs')}>
                Internet Explorer 
              </List.Item>
            </List>
          <span>Programs</span>
          </List.Item>
          <List.Item icon={<FolderFile variant="32x32_4" />}>
            <span>Documents</span>
            <List width={'153'}>
              <List.Item icon={<Help />} onClick={() => handleClick('about')}>
                About
              </List.Item>
              <List.Item icon={<Mail2 />} onClick={() => handleClick('contact')}>
                Contact
              </List.Item>
            </List>
          </List.Item>
          <List.Item icon={<Settings variant="32x32_4" />}>
            <List width={'153'}>
              <List.Item icon={<FolderSettings variant="16x16_4" />}>
                Control Panel
              </List.Item>
              <List.Item icon={<FolderPrint variant="16x16_4" />}>
                Printers
              </List.Item>
            </List>
            Settings
          </List.Item>
          <List.Item icon={<FileFind variant="32x32_4" />}>Find</List.Item>
          <List.Item icon={<HelpBook />}>
            Help
            <List width={'153'}>
              
                <List.Item icon={<Awfxcg321305 variant="32x32_4"/>} onClick={() => handleClick('randomness')}>
                  How to Buy $GODFATHER
                </List.Item>
                <List.Item icon={<FolderSettings variant="32x32_4"/>} onClick={() => handleClick('timelock')}>
                  Solana Tools
                </List.Item>
                <List.Item icon={<Progman15 variant="32x32_4"/>} onClick={() => handleClick('dtxs')}>
                  $GODFATHER Charts
                </List.Item>
              
            </List>
          </List.Item>
          <List.Item icon={<LoaderBat variant="32x32_4" />}>Run...</List.Item>
          <List.Divider />
          <List.Item icon={<Computer3 variant="32x32_4" />}>Shut Down...</List.Item>
        </List>
      } />
      {showWinamp && <WinampPlayer />}
    </>
  );
}
