import React from 'react';

function Win95coinEmbed() {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: `
                    <style>
                        .iframe-container {
                            position: relative;
                            width: 100%;
                            height: 370px;
                            
                        }
                        .iframe-container iframe {
                            width: 167%;
                            height: 620px;
                            border: 0;
                            transform: scale(0.6); /* Scale down the iframe content */
                            transform-origin: top left; /* Scale from the top left corner */
                        }
                        
                    </style>
                    <div class="iframe-container">
                        <iframe src="https://coins95.web.app/coins"></iframe> 
                    </div>
                `,
            }}
        />
    );
}

export default Win95coinEmbed;
